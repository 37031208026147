@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
}

@keyframes pulse {
  0%,
  20% {
    transform: rotate(15deg) scale(1);
  }

  80%,
  100% {
    transform: rotate(17deg) scale(1.05);
  }
}