* {
  /* --tw-ring-offset-color: rgb(15, 23, 42) !important; */
}

html {
  -webkit-overflow-scrolling: touch;
  /*
  height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
    */
}

body {
  position: relative;
}

* {
  user-select: none;
}

input, select, textarea {
  user-select: auto; 
}

.group:hover + .group .group-hover\:hidden {
  display: none;
}

#root:not(:last-child) {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.safe-area-inset-top {
  /* height: env(safe-area-inset-top); */
}

input::-webkit-date-and-time-value {
  text-align: left;
}

input {
  color-scheme: dark;
}

@media all and (display-mode: standalone) {
  #root {
    display: block;
  }
  #pwa-prompt {
    /* display: none; */
  }
}

.router-transition-enter {
  transition: opacity 100ms ease-in-out;
  z-index: 1;
  opacity: 0;
}

.router-transition-enter-active {
  opacity: 1;
}

.router-transition-exit {
  transition: opacity 100ms ease-in-out;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 1;
}

.router-transition-exit-active {
  opacity: 0;
}

.yo {
  position: relative;
}

.yo > * {
  width: 100vw;
  min-height: 100vh;
}

.yo > :not(:first-child) {
}

.yo > :first-child {
  z-index: 20; /* Prevent flickering on first frame when transition classes not added yet */
  position: absolute;
  top: 0;
  left: 0;
}

/* Transitions */
.next-exit-active {
  animation: leaveToLeft 300ms both cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 0;
}

.next-enter-active {
  animation: enterFromRight 300ms both cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.prev-exit-active {
  animation: leaveToRight 300ms both cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
}

.prev-enter-active {
  animation: enterFromLeft 300ms both cubic-bezier(0.4, 0, 0.2, 1);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

@keyframes leaveToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-25%);
    /* filter: brightness(0.5); */
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-25%);
    /* filter: brightness(0.5); */
  }
  to {
    transform: translateX(0);
  }
}

@keyframes leaveToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
